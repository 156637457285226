import Vue from 'vue'
import Vuex from 'vuex'
import { api } from '@/services'
import Alunos from './modules/usuarios'

/* eslint-disable */
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    user: JSON.parse(localStorage.user || '{}'),
    token: localStorage.token || undefined,
    transcript: localStorage.transcript || undefined,
    transcriptions: [],
    messages: [],
    apiChatList: [],
    menuMobile: false,
    speed: localStorage.speed || 1,
    chats: [], // Removido a duplicidade da propriedade 'user'
    selectedChat: null // Adicionando a propriedade para armazenar o chat selecionado
  },
  getters: {
    userName: state => state.user.name || '',
    isLogged: state => Object.keys(state.user).length !== 0 && state.token !== undefined,
    getTranscript: state => state.transcript,
    getTranscriptions: state => state.transcriptions,
    getMessages: state => state.messages,
    getChats: state => state.chats, // Getter para obter chats
    selectedChat: state => state.selectedChat // Getter para obter chat selecionado
  },
  mutations: {
    setChats(state, chats) {
      state.chats = chats;
    },
    setSelectedChat(state, chat) {
      state.selectedChat = chat;
    },
    login: (state, payload) => {
      state.user = payload.user;
      state.token = payload.token;
    },
    logout: state => {
      state.user = {};
      state.token = undefined;
    },
    toggleMenuMobile: state => {
      state.menuMobile = !state.menuMobile;
    },
    SAVE_TRANSCRIPT(state, transcript) {
      state.transcript = transcript; // Corrigido para usar o parâmetro corretamente
    },
    SAVE_TRANSCRIPTIONS(state, transcriptions) {
      state.transcriptions = transcriptions;
    },
    pushMessages: (state, payload) => {
      state.messages = payload;
    },
    updateApiList: (state, payload) => {
      state.apiChatList = payload;
    },
    updateSpeed: (state, payload) => {
      state.speed = payload || 1;
    }
  },
  actions: {
    async fetchChats({ commit }) {
      try {
        const response = await api.get('/getuserchats');
        commit('setChats', response.data.chats);
      } catch (error) {
        console.error("Erro ao buscar chats:", error);
      }
    },
    selectChat({ commit }, chatData) {
        commit('setSelectedChat', chatData);
    },
    login: (context, payload) => {
      localStorage.user = JSON.stringify(payload.user);
      localStorage.token = payload.token;
      api.defaults.headers.common.Authorization = `Bearer ${payload.token}`;
      context.commit('login', payload);
    },
    logout: context => {
      localStorage.clear();
      delete api.defaults.headers.common.Authorization;
      context.commit('logout');
    },
    menuMobile: context => {
      context.commit('toggleMenuMobile');
    },
    pushMessages: (context, payload) => {
      context.commit('pushMessages', payload);
    },
    updateApiList: (context, payload) => {
      context.commit('updateApiList', payload);
    },
    updateSpeed: (context, payload) => {
      localStorage.speed = payload;
      context.commit('updateSpeed', payload);
    }
  },
  modules: {
    alunos: Alunos
  }
});
